const List = require('../components/trackgroups')
const SwaggerClient = require('swagger-client')
const setTitle = require('../lib/title')

function trackgroups () {
  return (state, emitter) => {
    state.releases = state.releases || {
      items: []
    }

    state.trackgroups = state.trackgroups || {
      items: []
    }

    state.trackgroup = state.trackgroup || {
      data: {},
      formData: {
        type: 'ep'
      }
    }

    emitter.on('trackgroups:clear', () => {
      state.trackgroups.items = []
      emitter.emit(state.events.RENDER)
    })

    emitter.on('trackgroup:clear', () => {
      state.trackgroup.loaded = false
      state.trackgroup.notFound = false
      state.trackgroup.data = {}
      emitter.emit(state.events.RENDER)
    })

    emitter.on('trackgroup:items:add', async (props) => {
      const tracks = props.tracks || []

      if (!tracks.length && props.track_id) {
        tracks.push(props)
      }

      try {
        const response = await state.api.trackgroups.items.add({
          id: state.params.id,
          tracks: tracks.map((item) => {
            return {
              track_id: item.track_id
            }
          })
        })

        state.trackgroup.data.items = response.data

        tracks.forEach((item) => {
          emitter.emit('notify', {
            timeout: 5000,
            type: 'success',
            message: `${item.title} has been added successfully.`
          })
        })

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('trackgroup:items:update', async (props) => {
      const tracks = props.tracks || []

      if (!tracks.length && props.track_id) {
        tracks.push(props)
      }

      try {
        const response = await state.api.trackgroups.items.update({ id: state.params.id, tracks })

        state.trackgroup.data.items = response.data

        emitter.emit('notify', { type: 'success', message: 'All items updated successfully.' })

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('trackgroup:items:remove', async (props) => {
      const tracks = props.tracks || []
      const { title } = props

      if (!tracks.length && props.track_id) {
        tracks.push(props)
      }

      try {
        const response = await state.api.trackgroups.items.remove({
          id: state.params.id,
          tracks
        })

        state.trackgroup.data.items = response.data

        emitter.emit('notify', { type: 'success', message: `${title} has been removed successfully.` })

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('trackgroups:find', async (props = {}) => {
      const id = state.route.replace(/\//g, '-')
      const component = state.components[id]
      const { machine } = component

      if (machine.state === 'loading') {
        return
      }

      const loaderTimeout = setTimeout(() => {
        machine.emit('loader:toggle')
      }, 1000)

      machine.emit('request:start')

      const { private: _private, type, featured, limit = 20, page = 1, download, enabled } = props

      const payload = {
        limit: limit,
        page: page
      }

      if (download) {
        payload.download = true
      }

      if (featured) {
        payload.featured = true
      }

      if (_private) {
        payload.private = true
      }

      if (type) {
        payload.type = type
      }

      if (enabled) {
        payload.enabled = enabled
      }

      try {
        const response = await state.api.trackgroups.find(payload)

        if (response.status !== 'ok' || !Array.isArray(response.data)) {
          component.error = response
          return machine.emit('request:error')
        }

        if (!response.data.length) {
          return machine.emit('request:noResults')
        }

        machine.state.loader === 'on' && machine.emit('loader:toggle')
        machine.emit('request:resolve')

        state.trackgroups.items = response.data
        state.trackgroups.count = response.count
        state.trackgroups.pages = response.numberOfPages || 1

        emitter.emit(state.events.RENDER)
      } catch (err) {
        component.error = err
        machine.emit('request:reject')
        emitter.emit('error', err)
        // TODO handle status code (need to update factory generator)
      } finally {
        clearTimeout(loaderTimeout)
      }
    })

    emitter.on('trackgroups:findOne', async (props) => {
      try {
        const response = await state.api.trackgroups.findOne({
          id: props.id
        })

        if (!response.data) {
          state.trackgroup.notFound = true
          state.trackgroup.loaded = true

          emitter.emit(state.events.RENDER)

          return emitter.emit('notify', { message: 'Resource does not exist' })
        }

        state.trackgroup.data = response.data
        state.trackgroup.loaded = true

        setMeta()

        emitter.emit(state.events.RENDER)

        const user = await state.api.users.findOne({
          id: response.data.creator_id
        })

        state.trackgroup.user = user.data

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('route:user/releases/:id/update', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroups:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
    })

    emitter.on('route:user/playlists/:id/update', async () => {
      state.trackgroup.type = 'playlist'

      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroups:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
    })

    emitter.on('route:user/releases/:id/tracks', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroups:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
    })

    emitter.on('route:user/releases/:id/metadata', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroups:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
    })

    emitter.on('route:user/playlists/:id/tracks', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroups:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
    })

    emitter.on('route:user/releases/:id', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id })
      emitter.emit('tracks:find')
    })

    emitter.on('route:user/playlists/new', () => {
      state.trackgroup.type = 'playlist'

      emitter.emit(state.events.RENDER)
    })

    emitter.on('route:user/playlists/:id', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { id: state.params.id, type: 'playlist' })
      emitter.emit('tracks:find')
    })

    emitter.on('route:user/releases/new', () => {
      state.trackgroup.type = 'ep'

      emitter.emit(state.events.RENDER)
    })

    emitter.on('route:user/admin', async () => {
      state.cache(List, 'latest-releases')

      const component = state.components['latest-releases']
      const { machine } = component

      if (machine.state === 'loading') {
        return
      }

      const loaderTimeout = setTimeout(() => {
        machine.emit('loader:toggle')
      }, 1000)

      machine.emit('request:start')

      const url = new URL('/v2/trackgroups/apiDocs', `https://${process.env.OPENAPI_DOMAIN}`)

      url.search = new URLSearchParams({
        type: 'apiDoc',
        basePath: '/v2/trackgroups'
      })

      try {
        const client = await new SwaggerClient(url.href)
        const { body } = await client.apis.trackgroups.getTrackgroups({ limit: 4 })
        const { data } = body

        if (!data.length) {
          return machine.emit('request:noResults')
        }

        machine.emit('request:resolve')

        state.releases.items = data

        emitter.emit(state.events.RENDER)
      } catch (err) {
        component.error = err.response.body
        machine.emit('request:reject')
        emitter.emit('error', err)
      } finally {
        machine.state.loader === 'on' && machine.emit('loader:toggle')
        clearTimeout(loaderTimeout)
      }
    })

    emitter.on('route:user/releases', () => {
      emitter.emit('trackgroups:clear')

      state.cache(List, 'user-releases')

      emitter.emit('trackgroups:find', Object.assign({}, state.query))
    })

    emitter.on('route:user/playlists', () => {
      emitter.emit('trackgroups:clear')

      state.cache(List, 'user-playlists')

      emitter.emit('trackgroups:find', { type: 'playlist' })
    })

    /**
     * Admin routes
     */

    emitter.on('route:user/admin/releases', () => {
      emitter.emit('trackgroups:clear')

      state.cache(List, 'user-admin-releases')

      emitter.emit('trackgroups:find', Object.assign({ admin: true }, state.query))
    })

    emitter.on('route:user/admin/playlists', () => {
      emitter.emit('trackgroups:clear')

      state.cache(List, 'user-admin-playlists')

      emitter.emit('trackgroups:find', Object.assign({ admin: true, type: 'playlist' }, state.query))
    })

    emitter.on('route:user/admin/releases/:id', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { admin: true, id: state.params.id })
    })

    emitter.on('route:user/admin/releases/:id/tracks', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { admin: true, id: state.params.id })
    })

    emitter.on('route:user/admin/releases/:id/metadata', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { admin: true, id: state.params.id })
    })

    emitter.on('route:user/admin/releases/:id/update', () => {
      if (state.trackgroup.data.id !== state.params.id) {
        emitter.emit('trackgroup:clear')
      }

      emitter.emit('trackgroups:findOne', { admin: true, id: state.params.id })
    })

    function setMeta () {
      const trackgroupTitle = state.trackgroup.loaded ? state.trackgroup.data.title : false
      const title = {
        'user/releases/:id': trackgroupTitle || '...',
        'user/releases/:id/update': trackgroupTitle ? `Update ${trackgroupTitle}` : '...',
        'user/releases/:id/tracks': trackgroupTitle ? `Upload tracks to ${trackgroupTitle}` : '...'
      }[state.route]

      if (!title) return

      const fullTitle = setTitle(title)

      emitter.emit('meta', {
        title: fullTitle,
        'twitter:card': 'summary_large_image',
        'twitter:title': fullTitle,
        'twitter:site': '@resonatecoop'
      })
    }
  }
}

module.exports = trackgroups
