const Component = require('choo/component')
const html = require('choo/html')
const clone = require('shallow-clone')
const nanostate = require('nanostate')
const Loader = require('@resonate/play-count-component')
const compare = require('nanocomponent/compare')
const renderMessage = require('../../elements/message')
const moment = require('moment')

class Earnings extends Component {
  constructor (id, state, emit) {
    super(id)

    this.state = state
    this.emit = emit

    this.local = state.components[id] = Object.create({
      machine: nanostate.parallel({
        request: nanostate('idle', {
          idle: { start: 'loading' },
          loading: { resolve: 'data', reject: 'error', reset: 'idle' },
          data: { reset: 'idle', start: 'loading' },
          error: { reset: 'idle', start: 'loading' }
        }),
        loader: nanostate('off', {
          on: { toggle: 'off' },
          off: { toggle: 'on' }
        })
      })
    })

    this.local.error = {}
    this.local.amount = 0

    this.local.machine.on('request:noResults', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.transitions.request.event('noResults', nanostate('noResults', {
      noResults: { start: 'loading' }
    }))

    this.local.machine.on('loader:toggle', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.on('request:reject', () => {
      if (this.element) this.rerender()
    })
  }

  createElement (props) {
    const state = this.state
    const emit = this.emit

    this.local.from = props.from
    this.local.to = props.to
    this.local.items = clone(props.items)
    this.local.amount = props.amount

    const machine = {
      loading: {
        on: () => {
          const loader = new Loader('loader', state, emit).render({
            count: 3,
            options: { animate: true, repeat: true, reach: 9, fps: 10 }
          })

          return html`
            <div class="flex flex-column flex-auto items-center justify-center h5">
              ${loader}
            </div>
          `
        },
        off: () => {}
      }[this.local.machine.state.loader],
      noResults: () => {
        const start = moment(this.local.from).format('L')
        const end = moment(this.local.to).format('L')
        return renderMessage({
          message: `No earnings from ${start} to ${end}`
        })
      },
      error: () => renderMessage({ type: 'error', message: this.local.error.message }),
      data: () => {
        return renderTable(this.local)
      }
    }[this.local.machine.state.request]

    return html`
      <div class="flex flex-column flex-auto">
        <div class="flex flex-column ph3">
          <div class="flex">
            <h2 class="mv3 f3 fw4">
              Earnings
              <small class="f5 lh-copy">From ${moment(this.local.from).format('LL')} to ${moment(this.local.to).format('LL')}</small>
            </h2>
            ${renderEarningsTotal(this.local.amount)}
          </div>
        </div>
        <div class="flex ph3 ph0-ns overflow-auto">
          ${machine()}
        </div>
      </div>
    `

    function renderEarningsTotal (amount, currency = 'eur') {
      const sign = { eur: 'Ã¢ÂÂ¬', usd: '$' }[currency]
      const digits = amount > 0 && amount < 0.1 ? 3 : 2
      const payout = `${sign} ${amount.toFixed(digits)}`

      return html`
        <div class="flex flex-auto items-center justify-end">
          <div class="mr3">
            <span class="b ph2">${payout}</span>
          </div>
        </div>
      `
    }

    function renderTable (props) {
      const { items } = props

      return items.map(item => {
        const { tableRows, headRows } = item

        return html`
          <table class="f6 ph3 pr2" cellspacing="0">
            <thead>
              <tr class="stripe-dark">
                ${headRows.map((row) => {
                  return html`
                    <th class="fw1 f5 tl pl1 pl3-l pv3 bg-white">${row.name}</th>
                  `
                })}
              </tr>
            </thead>
            <tbody class="lh-copy">
              ${tableRows.map(item => {
                return html`
                  <tr class="stripe-dark">
                    ${Object.entries(item).map(([k, v]) => {
                      if (v !== null && typeof v === 'object') {
                        return html`<td class="pl1 pl3-l pv3">${v.html}</td>`
                      }
                      return html`<td class="truncate pl1 pl3-l pv3">${v}</td>`
                    })}
                  </tr>
                `
              })}
            </tbody>
          </table>
        `
      })
    }
  }

  update (props) {
    return compare(this.local.items, props.items) ||
      this.local.from !== props.from ||
      this.local.to !== props.to ||
      this.local.amount !== props.amount
  }
}

module.exports = Earnings
