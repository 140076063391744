const fetch = require('node-fetch')

module.exports = faq

function faq () {
  return (state, emitter) => {
    state.faq = state.faq || {
      items: []
    }

    emitter.once('faq:prefetch', (props = {}) => {
      const { lang = 'en-US' } = props

      const request = fetch(`${process.env.APP_HOST}/i18n/content/${lang}/faq/index.json`)
        .then(res => res.json())
        .then(data => {
          state.faq.items = data

          emitter.emit(state.events.RENDER)
        }).catch(err => {
          throw err
        })

      if (state.prefetch) state.prefetch.push(request)
    })

    emitter.on('faq:find', async (props = {}) => {
      const { lang = 'en-US' } = props

      try {
        const response = await (await fetch(`/i18n/content/${lang}/faq/index.json`)).json()

        state.faq.items = response

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('route:faq', () => {
      emitter.emit('faq:find', { lang: 'en-US' })
    })
  }
}
