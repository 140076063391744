const html = require('choo/html')
const Layout = require('../../elements/layout')
const { isNode } = require('browser-or-node')
const raw = require('nanohtml/raw')

module.exports = Layout(view)

function view (state, emit) {
  if (isNode) {
    emit('faq:prefetch')
  }

  return html`
    <div class="flex flex-column flex-auto w-100">
      <section id="faq" class="flex flex-column w-100 ph3 pb6">
        <h1 class="f3 lh-title fw4">Frequently asked questions</h1>

        <dl class="ma0 measure">
          ${state.faq.items.map((item) => {
            const { question: q, answer: a } = item

            return html`
              <dt class="b mb2">${q}</dt>
              <dd class="ma0 lh-copy mb3">
                ${raw(a)}
              </dd>
            `
          })}
        </dl>
      </section>
    </div>
  `
}
