const Component = require('choo/component')
const html = require('choo/html')
const imagePlaceholder = require('../../lib/image-placeholder')

class Item extends Component {
  constructor (id, state, emit) {
    super(id)

    this.state = state
    this.emit = emit
    this.local = {}
  }

  createElement (props) {
    this.local.item = props.data || {}

    const { cover: src, id, title, artist } = this.local.item

    return html`
      <li class="stripe-dark mb1">
        <a href="${this.state.href}/${id}" class="db link flex flex-row">
          <div class="fl w-100 mw3">
            <div class="aspect-ratio aspect-ratio--1x1 bg-dark-gray bg-dark-gray--dark">
              <figure class="ma0">
                <img src=${src || imagePlaceholder(120, 120)} width=120 height=120 class="aspect-ratio--object z-1"/>
                <figcaption class="clip">${title}</figcaption>
              </figure>
            </div>
          </div>
          <div class="flex-auto">
            <span class="f4 fw1 db black-70 pl2">${title}</span>
            <span class="f6 db black-70 pl2">${artist}</span>
          </div>
        </a>
      </li>
    `
  }

  update () {
    return true
  }
}

module.exports = Item
