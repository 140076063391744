const List = require('../components/tracks')

function tracks () {
  return (state, emitter) => {
    state.tracks = state.tracks || {
      items: []
    }

    state.track = state.track || {
      data: {}
    }

    emitter.on('tracks:findOne', async (props = {}) => {
      const { admin = false, id } = props

      try {
        const response = await state.api.tracks.findOne({ id })

        state.track.data = response.data

        emitter.emit(state.events.RENDER)

        if (admin === true) {
          const user = await state.api.users.findOne({
            id: response.data.creator_id
          })

          state.track.user = user.data

          emitter.emit(state.events.RENDER)
        }
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('tracks:find', async (props = {}) => {
      const id = state.route.replace(/\//g, '-')
      const component = state.components[id]
      const { machine } = component

      if (machine.state === 'loading') {
        return
      }

      const loaderTimeout = setTimeout(() => {
        machine.emit('loader:toggle')
      }, 1000)

      machine.emit('request:start')

      try {
        const payload = Object.assign({}, props, state.query)
        const response = await state.api.tracks.find(payload)

        if (response.status !== 'ok' || !Array.isArray(response.data)) {
          component.error = response
          return machine.emit('request:error')
        }

        if (!response.data.length) {
          return machine.emit('request:noResults')
        }

        machine.state.loader === 'on' && machine.emit('loader:toggle')
        machine.emit('request:resolve')

        state.tracks.items = response.data
        state.tracks.numberOfPages = response.numberOfPages

        emitter.emit(state.events.RENDER)
      } catch (err) {
        component.error = err
        machine.emit('request:reject')
        emitter.emit('error', err)
      } finally {
        clearTimeout(loaderTimeout)
      }
    })

    emitter.on('route:user/tracks', () => {
      state.cache(List, 'user-tracks')

      emitter.emit('tracks:find')
    })

    emitter.on('route:user/admin/tracks', () => {
      state.cache(List, 'user-admin-tracks')

      emitter.emit('tracks:find')
    })

    emitter.on('route:user/tracks/:id', () => {
      const id = Number(state.params.id)
      emitter.emit('tracks:findOne', { id })
    })

    emitter.on('route:user/admin/tracks/:id', () => {
      const id = Number(state.params.id)
      emitter.emit('tracks:findOne', { id, admin: true })
    })

    emitter.on('route:user/tracks/:id/update', () => {
      const id = Number(state.params.id)
      emitter.emit('tracks:findOne', { id })
    })

    emitter.on('route:user/admin/tracks/:id/update', () => {
      const id = Number(state.params.id)
      emitter.emit('tracks:findOne', { id, admin: true })
    })
  }
}

module.exports = tracks
