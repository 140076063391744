const choo = require('choo')

const { isBrowser } = require('browser-or-node')
const lazy = require('choo-lazy-view')
const { tabbing } = require('@resonate/choo-plugins')

const Layout = require('./elements/layout')

const app = choo()
app.use(lazy)

if (isBrowser) {
  require('web-animations-js/web-animations.min')

  window.localStorage.DISABLE_NANOTIMING = process.env.DISABLE_NANOTIMING === 'yes'
  window.localStorage.logLevel = process.env.LOG_LEVEL

  if (process.env.NODE_ENV !== 'production') {
    app.use(require('choo-devtools')())
  }

  if ('Notification' in window) {
    app.use(require('choo-notification')())
  }
}

/**
 * More plugins and stores
 */

app.use(tabbing())
app.use(require('choo-meta')())
app.use(require('./plugins/consent')())
app.use(require('./stores/notifications')())
app.use(require('./stores/app')())
app.use(require('./stores/profile')())
app.use(require('./stores/tracks')())
app.use(require('./stores/trackgroups')())
app.use(require('./stores/faq')())
app.use(require('./stores/earnings')())
app.use(require('./stores/files')())
app.use(require('./stores/users')())

/**
 * Routing
 */

app.route('/', Layout(require('./views/main')))

app.route('/user/members', lazy(() => import('./views/users')))
app.route('/user/releases', lazy(() => import('./views/trackgroups')))
app.route('/user/releases/new', lazy(() => import('./views/trackgroups/create')))
app.route('/user/releases/:id', lazy(() => import('./views/trackgroups/item')))
app.route('/user/releases/:id/update', lazy(() => import('./views/trackgroups/update')))
app.route('/user/releases/:id/tracks', lazy(() => import('./views/trackgroups/tracks')))
app.route('/user/releases/:id/metadata', lazy(() => import('./views/trackgroups/metadata')))

app.route('/user/playlists', lazy(() => import('./views/trackgroups')))
app.route('/user/playlists/new', lazy(() => import('./views/trackgroups/create')))
app.route('/user/playlists/:id/update', lazy(() => import('./views/trackgroups/update')))
app.route('/user/playlists/:id', lazy(() => import('./views/trackgroups/item')))

app.route('/user/earnings', lazy(() => import('./views/earnings')))
app.route('/user/earnings/analytics', lazy(() => import('./views/earnings/analytics')))
app.route('/user/plays/analytics', lazy(() => import('./views/plays/analytics')))

app.route('/user/files', lazy(() => import('./views/files')))
app.route('/user/files/:id', lazy(() => import('./views/files/item')))

app.route('/user/tracks', lazy(() => import('./views/tracks')))
app.route('/user/tracks/:id', lazy(() => import('./views/tracks/item')))
app.route('/user/tracks/:id/update', lazy(() => import('./views/tracks/update')))

app.route('/faq', require('./views/faq'))

app.route('/support', lazy(() => import('./views/support')))

/**
 * Admin routes
 */

app.route('/user/admin', lazy(() => import('./views/admin')))
app.route('/user/admin/users', lazy(() => import('./views/users')))
app.route('/user/admin/users/:id', lazy(() => import('./views/users/item')))
app.route('/user/admin/users/:id/earnings', lazy(() => import('./views/earnings')))
app.route('/user/admin/users/:id/earnings/analytics', lazy(() => import('./views/earnings/analytics')))
app.route('/user/admin/users/:id/plays/analytics', lazy(() => import('./views/plays/analytics')))
app.route('/user/admin/users/new', lazy(() => import('./views/users/create')))

app.route('/user/admin/releases', lazy(() => import('./views/trackgroups')))
app.route('/user/admin/releases/new', lazy(() => import('./views/trackgroups/create')))
app.route('/user/admin/releases/:id', lazy(() => import('./views/trackgroups/item')))
app.route('/user/admin/releases/:id/update', lazy(() => import('./views/trackgroups/update')))
app.route('/user/admin/releases/:id/tracks', lazy(() => import('./views/trackgroups/tracks')))
app.route('/user/admin/releases/:id/metadata', lazy(() => import('./views/trackgroups/metadata')))

app.route('/user/admin/playlists', lazy(() => import('./views/trackgroups')))
app.route('/user/admin/playlists/new', lazy(() => import('./views/trackgroups/create')))
app.route('/user/admin/playlists/:id/update', lazy(() => import('./views/trackgroups/update')))
app.route('/user/admin/playlists/:id', lazy(() => import('./views/trackgroups/item')))

app.route('/user/admin/tracks', lazy(() => import('./views/tracks')))
app.route('/user/admin/tracks/:id', lazy(() => import('./views/tracks/item')))
app.route('/user/admin/tracks/:id/update', lazy(() => import('./views/tracks/update')))

app.route('/*', lazy(() => import('./views/404')))

module.exports = app.mount('#app')
