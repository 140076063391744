function users () {
  return (state, emitter) => {
    state.users = state.users || {
      items: []
    }

    state.user = state.user || {
      data: {}
    }

    emitter.on('users:find', async (props = {}) => {
      const pageNumber = state.query.page ? Number(state.query.page) : 1

      try {
        const payload = Object.assign({}, {
          page: pageNumber,
          limit: 20
        }, state.query)

        const response = await state.api.users.find(payload)

        if (response.data) {
          state.users.items = response.data
          state.users.numberOfPages = response.numberOfPages || 1
        }

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('users:findOne', async (props = {}) => {
      try {
        const response = await state.api.users.findOne({ id: props.id })

        if (response.data) {
          state.user.data = response.data
        }

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('route:user/profiles', () => {
      emitter.emit('users:find', { limit: 10 })
    })

    emitter.on('route:user/profiles/:id', () => {
      emitter.emit('users:findOne', { id: state.params.id })
    })

    emitter.on('route:user/admin/users', () => {
      emitter.emit('users:find', { limit: 10 })
    })

    emitter.on('route:user/admin/users/:id', () => {
      emitter.emit('users:findOne', { id: state.params.id })
    })
  }
}

module.exports = users
