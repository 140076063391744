"use strict";

exports.__esModule = true;
exports.default = void 0;

const foldFormDataToRequest = (formdata, request) => {
  request.body = formdata;
};

var _default = foldFormDataToRequest;
exports.default = _default;