const moment = require('moment')
const EarningsTable = require('../components/earnings')

function earnings () {
  return (state, emitter) => {
    const defaultPeriodStart = moment()
      .subtract(12, 'months')
      .format('YYYY-MM-DD')

    const defaultPeriodEnd = moment()
      .format('YYYY-MM-DD')

    state.earnings = state.earnings || {
      periodStart: state.query.from || defaultPeriodStart,
      periodEnd: state.query.to || defaultPeriodEnd,
      items: [],
      stats: {
        sums: {
          artist_total: 0,
          artist_total_eur: 0,
          resonate_total: 0,
          resonate_total_eur: 0
        }
      }
    }

    emitter.on('earnings:find', async () => {
      state.earnings.periodStart = state.query.from || defaultPeriodStart
      state.earnings.periodEnd = state.query.to || defaultPeriodEnd

      const component = state.components['earnings-table']

      const { machine } = component

      if (machine.state === 'loading') {
        return
      }

      const loaderTimeout = setTimeout(() => {
        machine.emit('loader:toggle')
      }, 1000)

      machine.emit('request:start')

      try {
        const payload = {
          date: {
            from: state.earnings.periodStart, // first day of current month
            to: state.earnings.periodEnd // first day of next month
          }
        }

        if (state.params.id) {
          payload.creator_id = Number(state.params.id)
        }

        const response = await state.api.earnings.find(payload)

        machine.state.loader === 'on' && machine.emit('loader:toggle')

        if (response.status !== 'ok' || !Array.isArray(response.data)) {
          component.error = response
          return machine.emit('request:error')
        }

        if (!response.data.length) {
          return machine.emit('request:noResults')
        }

        machine.emit('request:resolve')

        state.earnings.loaded = true

        if (response.data) {
          state.earnings.items = response.data
          state.earnings.stats = response.stats
        }

        emitter.emit(state.events.RENDER)
      } catch (err) {
        machine.emit('request:reject')
        emitter.emit('error', err)
      } finally {
        clearTimeout(loaderTimeout)
      }
    })

    emitter.on('route:user/earnings', () => {
      state.cache(EarningsTable, 'earnings-table')

      emitter.emit('earnings:find')
    })

    emitter.on('route:user/admin/users/:id/earnings', () => {
      state.cache(EarningsTable, 'earnings-table')

      emitter.emit('earnings:find')
    })
  }
}

module.exports = earnings
