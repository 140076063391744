function profile () {
  return (state, emitter) => {
    state.profile = state.profile || {
      resolved: false,
      data: {}
    }

    emitter.on('route:user/profile/edit', () => {
      emitter.emit(state.events.RENDER)
    })
  }
}

module.exports = profile
