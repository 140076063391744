const createAPI = require('./api-gen')
const moment = require('moment')

/**
 * TODO
 * use @resonate/schemas and update api-factory-generator
 */

const TrackgroupSchema = {
  type: 'object',
  additionalProperties: false,
  // required: ['title', 'type', 'release_date', 'cover'],
  properties: {
    id: {
      type: 'string',
      format: 'uuid'
    },
    title: {
      type: 'string'
    },
    display_artist: {
      type: 'string'
    },
    private: {
      type: 'boolean'
    },
    cover: {
      type: 'string',
      format: 'uuid'
    },
    release_date: {
      type: 'string',
      format: 'date'
    },
    type: {
      type: 'string',
      enum: ['lp', 'ep', 'single', 'playlist']
    },
    about: {
      type: 'string'
    },
    composers: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    performers: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    tags: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  }
}

const TrackGroupItemSchema = {
  type: 'object',
  required: ['track_id'],
  properties: {
    track_id: {
      type: 'number'
    },
    title: {
      type: 'string'
    },
    index: {
      type: 'number',
      minimum: 1
    }
  }
}

/**
 * REST API configuration
 * @param {object} options Options for apiFactoryGenerator
 */
module.exports = (options) => {
  const defaultOptions = {
    scheme: 'https://',
    domain: process.env.API_DOMAIN || 'upload.resonate.localhost',
    prefix: process.env.API_PREFIX || '/api/user',
    auth: true,
    version: 1
  }

  return createAPI({
    users: {
      find: {
        path: '/users',
        schema: {
          type: 'object',
          properties: {
            limit: {
              type: 'number',
              maximum: 100,
              minimum: 1
            },
            q: {
              type: 'string',
              minLength: 3
            },
            page: {
              type: 'number',
              minimum: 1
            },
            role: {
              type: 'string',
              enum: ['admin', 'resonate-coop', 'bands', 'member', 'label-owner', 'listener', 'fans']
            }
          }
        }
      },
      findOne: {
        path: '/users/[:id]',
        schema: {
          type: 'object',
          properties: {
            id: {
              type: 'number',
              minimum: 1
            }
          }
        }
      }
    },
    plays: {
      find: {
        path: '/plays',
        schema: {
          type: 'object',
          required: ['from', 'to'],
          properties: {
            period: {
              type: 'string',
              enum: ['yearly', 'daily', 'monthly']
            },
            from: {
              format: 'date',
              formatMaximum: moment().format('YYYY-MM-DD'),
              formatExclusiveMaximum: false
            },
            to: {
              format: 'date',
              formatMaximum: moment().format('YYYY-MM-DD'),
              formatExclusiveMaximum: false
            }
          }
        }
      }
    },
    files: {
      find: {
        path: '/files',
        schema: {
          type: 'object',
          properties: {
            limit: {
              type: 'number',
              maximum: 100,
              minimum: 1
            },
            type: {
              type: 'string',
              enum: ['audio', 'image', 'csv']
            },
            page: {
              type: 'number',
              minimum: 1
            }
          }
        }
      },
      findOne: {
        path: '/files/[:id]',
        schema: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
              format: 'uuid'
            }
          }
        }
      }
    },
    earnings: {
      find: {
        path: '/earnings',
        options: {
          method: 'POST'
        },
        schema: {
          type: 'object',
          properties: {
            date: {
              type: 'object',
              required: ['from', 'to'],
              properties: {
                from: {
                  format: 'date',
                  formatMaximum: moment().format('YYYY-MM-DD'),
                  formatExclusiveMaximum: false
                },
                to: {
                  format: 'date',
                  formatMaximum: moment().format('YYYY-MM-DD'),
                  formatExclusiveMaximum: false
                }
              }
            },
            creator_id: {
              type: 'number',
              minimum: 0
            },
            period: {
              type: 'string',
              enum: ['yearly', 'daily', 'monthly']
            },
            email: {
              type: 'string',
              format: 'email'
            }
          }
        }
      }
    },
    tracks: {
      find: {
        path: '/tracks',
        schema: {
          type: 'object',
          properties: {
            limit: {
              type: 'number'
            },
            page: {
              type: 'number'
            }
          }
        }
      },
      findOne: {
        path: '/tracks/[:id]',
        schema: {
          type: 'object',
          properties: {
            id: {
              type: 'number'
            }
          }
        }
      },
      update: {
        path: '/tracks/[:id]',
        options: {
          method: 'PUT'
        },
        schema: {
          type: 'object',
          properties: {
            id: {
              type: 'number',
              minimum: 1
            },
            creator_id: {
              type: 'number',
              minimum: 1
            },
            status: {
              type: 'string',
              enum: ['free', 'paid', 'hidden']
            },
            album: {
              type: 'string'
            },
            composer: {
              type: 'string'
            },
            title: {
              type: 'string'
            },
            year: {
              type: 'number',
              minimum: 1900,
              maximum: new Date().getFullYear() + 1
            }
          }
        }
      }
    },
    trackgroups: {
      find: {
        path: '/trackgroups',
        schema: {
          type: 'object',
          properties: {
            limit: {
              type: 'number'
            },
            page: {
              type: 'number'
            },
            type: {
              type: 'string',
              enum: ['lp', 'ep', 'single', 'playlist']
            },
            featured: {
              type: 'boolean'
            }
          }
        }
      },
      findOne: {
        path: '/trackgroups/[:id]',
        schema: {
          type: 'object',
          required: ['id'],
          properties: {
            id: {
              type: 'string',
              format: 'uuid'
            },
            type: {
              type: 'string',
              enum: ['lp', 'ep', 'single', 'playlist']
            }
          }
        }
      },
      remove: {
        path: '/trackgroups/[:id]',
        options: {
          method: 'DELETE'
        },
        schema: {
          type: 'object',
          required: ['id'],
          properties: {
            id: {
              type: 'string',
              format: 'uuid'
            }
          }
        }
      },
      items: {
        add: {
          path: '/trackgroups/[:id]/items/add',
          options: {
            method: 'PUT'
          },
          schema: {
            type: 'object',
            additionalProperties: false,
            required: ['tracks'],
            properties: {
              id: {
                type: 'string',
                format: 'uuid'
              },
              tracks: {
                type: 'array',
                items: TrackGroupItemSchema
              }
            }
          }
        },
        remove: {
          path: '/trackgroups/[:id]/items/remove',
          options: {
            method: 'PUT'
          },
          schema: {
            type: 'object',
            additionalProperties: false,
            required: ['tracks'],
            properties: {
              id: {
                type: 'string',
                format: 'uuid'
              },
              tracks: {
                type: 'array',
                items: {
                  type: 'object',
                  required: ['track_id'],
                  properties: {
                    track_id: {
                      type: 'number'
                    }
                  }
                }
              }
            }
          }
        },
        update: {
          path: '/trackgroups/[:id]/items',
          options: {
            method: 'PUT'
          },
          schema: {
            type: 'object',
            additionalProperties: false,
            required: ['tracks'],
            properties: {
              id: {
                type: 'string',
                format: 'uuid'
              },
              tracks: {
                type: 'array',
                items: TrackGroupItemSchema
              }
            }
          }
        }
      },
      settings: {
        path: '/trackgroups/[:id]/settings',
        options: {
          method: 'PUT'
        },
        schema: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
              format: 'uuid'
            },
            creator_id: {
              type: 'number',
              minimum: 1
            },
            enabled: {
              type: 'boolean'
            },
            download: {
              type: 'boolean'
            },
            private: {
              type: 'boolean'
            }
          }
        }
      },
      update: {
        path: '/trackgroups/[:id]',
        options: {
          method: 'PUT'
        },
        schema: TrackgroupSchema
      },
      create: {
        path: '/trackgroups',
        options: {
          method: 'POST'
        },
        schema: TrackgroupSchema
      }
    },
    profile: {
      get: {
        path: '/profile'
      },
      remove: {
        path: '/profile',
        options: {
          method: 'DELETE'
        }
      },
      update: {
        path: '/profile',
        options: {
          method: 'PUT'
        },
        schema: {
          type: 'object',
          additionalProperties: false,
          required: ['email', 'nickname'],
          properties: {
            email: {
              type: 'string',
              format: 'email'
            },
            nickname: {
              type: 'string'
            }
          }
        }
      },
      updatePassword: {
        path: '/password',
        options: {
          method: 'PUT'
        },
        schema: {
          type: 'object',
          additionalProperties: false,
          required: ['password', 'password_new', 'password_confirm'],
          properties: {
            password: {
              type: 'string'
            },
            password_new: {
              type: 'string'
            },
            password_confirm: {
              type: 'string'
            }
          }
        }
      }
    },
    message: {
      path: '/message',
      options: {
        method: 'POST'
      },
      schema: {
        type: 'object',
        additionalProperties: false,
        required: ['email', 'message'],
        properties: {
          email: {
            type: 'string',
            format: 'email'
          },
          message: {
            type: 'string'
          }
        }
      }
    }
  }, Object.assign(defaultOptions, options))
}
