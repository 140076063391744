const Files = require('../components/files')

function files () {
  return (state, emitter) => {
    state.files = state.files || {
      items: []
    }

    state.file = state.file || {
      data: {}
    }

    state.track = state.track || {
      data: {}
    }

    emitter.on('file:clear', () => {
      state.file.data = {}
      emitter.emit(state.events.RENDER)
    })

    emitter.on('files:find', async () => {
      const { machine } = state.components['files-table']

      if (machine.state === 'loading') {
        return
      }

      const loaderTimeout = setTimeout(() => {
        machine.emit('loader:toggle')
      }, 1000)

      machine.emit('request:start')

      try {
        const response = await state.api.files.find(state.query)

        machine.state.loader === 'on' && machine.emit('loader:toggle')

        if (!response.data.length) {
          return machine.emit('request:noResults')
        }

        machine.emit('request:resolve')

        state.files.items = response.data

        emitter.emit(state.events.RENDER)
      } catch (err) {
        machine.emit('request:reject')
        emitter.emit('error', err)
      } finally {
        clearTimeout(loaderTimeout)
      }
    })

    emitter.on('files:findOne', async (props) => {
      const { id } = props

      try {
        const response = await state.api.files.findOne({ id })

        state.file.data = response.data

        emitter.emit(state.events.RENDER)
      } catch (err) {
        emitter.emit('error', err)
      }
    })

    emitter.on('route:user/files', () => {
      state.cache(Files, 'files-table')

      emitter.emit('files:find')
    })

    emitter.on('route:user/files/:id', () => {
      if (state.file.data.id !== state.params.id) {
        emitter.emit('file:clear')
      }

      emitter.emit('files:findOne', { id: state.params.id })
    })
  }
}

module.exports = files
