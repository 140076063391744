const html = require('choo/html')
const Component = require('choo/component')
const compare = require('nanocomponent/compare')
const icon = require('@resonate/icon-element')
const nanostate = require('nanostate')
const { foreground: fg } = require('@resonate/theme-skins')
const imagePlaceholder = require('../../lib/image-placeholder')

/**
 * Basic dropdown menu
 */

class DropdownMenu extends Component {
  constructor (id, state, emit) {
    super(id)

    this.emit = emit
    this.state = state

    this.local = state.components[id] = Object.create({
      machine: nanostate.parallel({
        focus: nanostate('off', {
          on: { toggle: 'off' },
          off: { toggle: 'on' }
        })
      })
    })

    this.local.machine.on('focus:toggle', () => {
    })
  }

  createElement (props) {
    this.local.text = props.text
    this.local.items = props.items || []
    this.local.scope = props.scope
    this.local.href = props.href || '/'
    this.local.style = props.style || 'left:0'
    this.local.showImage = props.showImage
    this.local.showIcon = props.showIcon
    this.local.imageSrc = props.imageSrc
    this.local.theme = props.theme
    this.local.reverse = props.reverse
    this.local.title = props.title

    const attrs = {
      type: 'button',
      title: this.local.title || 'menu',
      onfocus: (e) => {
        this.local.machine.emit('focus:toggle')
      },
      onblur: (e) => {
        this.local.machine.emit('focus:toggle')
      },
      class: 'bg-transparent link bn h-100'
    }

    function renderImage (src, opts = {}) {
      const { w = 32, h = 32 } = opts

      return html`
        <div class="fl w2">
          <div class="aspect-ratio aspect-ratio--1x1 bg-dark-gray bg-dark-gray--dark">
            <figure class="ma0">
              <img width=${w} height=${h} src=${src || imagePlaceholder(w, h)} class="aspect-ratio--object z-1"/>
              <figcaption class="clip">Profile pic</figcaption>
            </figure>
          </div>
        </div>
      `
    }

    function renderIcon (theme, name = 'dropdown') {
      return html`
        <div class="flex pa2 ml2">
          ${icon(name, { class: `fill-${theme === 'dark' ? 'light' : 'dark'}-gray` })}
        </div>
      `
    }

    return html`
      <li class="dropdown-menu relative mr3">
        <button ${attrs}>
          <div class="flex items-center">
            ${this.local.showImage ? renderImage(this.local.imageSrc) : ''}
            ${this.local.text}
            ${this.local.showIcon ? renderIcon(this.local.theme) : ''}
          </div>
        </button>
        <ul class="${fg} list ma0 pa2 absolute right-0 z-max vw-100 mw5" style=${this.local.style}>
          ${this.local.items.map(item => {
            if (item.html) return item.html

            const target = item.target || '_self'
            const href = item.href || (this.local.scope + item.path)
            const prefix = item.prefix || ''
            const attrs = {
              target: target,
              href: href,
              rel: target === '_blank' ? 'noopener noreferer' : '',
              class: 'link db dim w-100 pa2'
            }

            if (item.divider) {
              return html`
                <li class="lh-copy f6 gray pt3 pl2">
                  ${item.text}
                  <span class="db w-100 pb2 b--near-black bw bb"></span>
                </li>
              `
            }

            return html`
              <li class=${prefix}>
                <a ${attrs}>
                  ${item.text}
                </a>
              </li>
            `
          })}
        </ul>
      </li>
    `
  }

  update (props) {
    return props.style !== this.local.style ||
      props.theme !== this.local.theme ||
      props.scope !== this.local.scope ||
      compare(props.items, this.local.items)
  }
}

module.exports = DropdownMenu
