const Component = require('choo/component')
const html = require('choo/html')
const imagePlaceholder = require('../../lib/image-placeholder')
const moment = require('moment')

class Item extends Component {
  constructor (id, state, emit) {
    super(id)

    this.state = state
    this.emit = emit
    this.local = {}
  }

  createElement (props) {
    const state = this.state

    this.local.item = props.data
    this.local.layout = props.layout
    this.local.href = props.href || state.href

    const {
      type,
      about,
      title,
      cover: src,
      id,
      release_date: releaseDate
    } = this.local.item

    const url = new URL(this.local.href, 'http://localhost')

    if (id !== false) {
      url.pathname = this.local.href + '/' + id
    }

    const date = moment(releaseDate)
    const releaseYear = date.isValid() ? date.format('YYYY') : ''

    return html`
      <article class="fl w-100 w-50-ns w-25-l pa3">
        <div class="grow">
          <a href=${url.pathname} class="db link aspect-ratio aspect-ratio--1x1 bg-dark-gray bg-dark-gray--dark">
            <figure class="ma0">
              <img src=${src || imagePlaceholder(400, 400)} width=400 height=400 class="aspect-ratio--object z-1"/>
              <figcaption class="clip">${title}</figcaption>
            </figure>
          </a>
        </div>
        <a href=${url.pathname} class="link flex flex-auto">
          <div class="flex flex-column w-100 flex-auto pt2">
            ${type !== 'playlist'
            ? html`
                <div class="flex flex-auto">
                  <div class="flex flex-auto">
                    <span class="b ttu f5">${type}</span>
                  </div>
                  <div class="flex flex-auto justify-end">
                    <span class="b f5 near-black">${releaseYear}</span>
                  </div>
                </div>
              `
            : ''}
            <h4 class="lh-title f3 fw4 b mt1 mb2 truncate">${title}</h4>
            <p class="lh-copy f5 near-black ma0 pa0">${about}</p>
          </div>
        </a>
      </article>
    `
  }

  update () {
    return true
  }
}

module.exports = Item
