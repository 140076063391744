const html = require('choo/html')
const Component = require('choo/component')
const link = require('@resonate/link-element')
const icon = require('@resonate/icon-element')
const imagePlaceholder = require('../../lib/image-placeholder')
const onIntersect = require('on-intersect')
const animation = require('nanoanimation')
const classnames = require('classnames')
const DropdownMenu = require('../dropdown-menu')

const staff = ['admin', 'resonate-coop']

class Header extends Component {
  constructor (id, state, emit) {
    super(id)

    this.emit = emit
    this.state = state
    this.local = state.components[id] = {}

    this.local.profile = {
      resolved: false,
      data: {}
    }

    this.onEnter = this.onEnter.bind(this)
    this.onExit = this.onExit.bind(this)
  }

  createElement (props) {
    this.local.href = props.href
    this.local.profile = Object.assign({}, props.profile)

    const brand = link({
      href: '/',
      text: icon('logo', { size: 'md' }),
      prefix: 'link flex items-center flex-shrink-0 f5 h-100 grow ph3 overflow-hidden',
      title: 'Resonate Upload Tool'
    })

    const isAdminRoute = this.state.route.split('/')[1] === 'admin'

    const attrs = {
      role: 'banner',
      class: classnames(
        this.local.sticky ? 'sticky' : 'relative',
        'left-0 top-0 right-0 w-100 z-9999 flex items-center shadow-contour',
        `color-scheme--${isAdminRoute ? 'dark' : 'light'}`
      ),
      style: 'height:3rem'
    }

    const logged = !!this.local.profile.resolved && !!this.local.profile.data.id

    const profiles = this.local.profile.data.profiles || []

    return html`
      <header ${attrs}>
        <h1 class="mt0 mb0 ml2 flex items-center">
          ${brand}
        </h1>
        ${logged
          ? html`
          <nav role="navigation" aria-label="Main navigation" class="flex flex-auto w-100 h-100 items-center pl2">
            <ul class="list ma0 pa0 flex h-100">
              ${[
                {
                  text: 'Releases',
                  scope: isAdminRoute ? '/user/admin' : '/user',
                  roles: ['member', 'label-owner', 'bands', 'uploader'],
                  path: '/releases'
                },
                {
                  text: 'Earnings',
                  scope: '/user',
                  hidden: isAdminRoute,
                  roles: ['member', 'label-owner', 'bands'],
                  path: '/earnings'
                }
              ].filter(({ roles, hidden }) => {
                if (hidden) return false
                if (!roles) return true
                if (staff.includes(this.local.profile.data.role)) return true
                if (roles.includes(this.local.profile.data.role)) return true
                return false
              })
              .map((item) => {
                const href = item.href || (item.scope + item.path)

                return html`
                  <li class="dn db-ns mr3">
                    <a href=${href} class="link ${isAdminRoute ? 'light' : 'dark'}-gray flex items-center h-100">
                      ${item.text}
                    </a>
                  </li>
                `
              })}
              ${this.state.cache(DropdownMenu, 'header-user-dropdown').render({
                theme: isAdminRoute ? 'dark' : 'light',
                showIcon: true,
                showImage: false,
                reverse: true,
                scope: isAdminRoute ? '/user/admin' : '/user',
                href: '/dashboard',
                title: 'More',
                items: [
                  {
                    text: 'Releases',
                    roles: ['member', 'label-owner', 'bands'],
                    prefix: 'db dn-ns',
                    path: '/releases'
                  },
                  {
                    text: 'Users',
                    roles: ['admin', 'resonate-coop', 'uploader'],
                    hidden: !isAdminRoute,
                    path: '/users'
                  },
                  {
                    text: 'Tracks',
                    roles: ['admin', 'resonate-coop', 'uploader'],
                    hidden: !isAdminRoute,
                    path: '/tracks'
                  },
                  {
                    text: 'Earnings',
                    roles: ['member', 'label-owner', 'bands'],
                    prefix: 'db dn-ns',
                    hidden: isAdminRoute,
                    path: '/earnings'
                  },
                  {
                    text: 'Earnings + Analytics',
                    roles: ['member', 'label-owner', 'bands'],
                    hidden: isAdminRoute,
                    path: '/earnings/analytics'
                  },
                  {
                    text: 'Plays + Analytics',
                    roles: ['member', 'label-owner', 'bands'],
                    hidden: isAdminRoute,
                    path: '/plays/analytics'
                  },
                  {
                    text: 'Tracks',
                    roles: ['member', 'label-owner', 'bands', 'uploader'],
                    hidden: isAdminRoute,
                    path: '/tracks'
                  },
                  {
                    text: 'Files',
                    roles: ['member', 'label-owner', 'bands'],
                    hidden: isAdminRoute,
                    path: '/files'
                  },
                  {
                    text: 'Playlists',
                    hidden: isAdminRoute,
                    path: '/playlists'
                  }
                ].filter(({ roles, hidden }) => {
                  if (hidden) return false
                  if (!roles) return true
                  if (staff.includes(this.local.profile.data.role)) return true
                  if (roles.includes(this.local.profile.data.role)) return true
                  return false
                })
              })}
            </ul>
          </nav>`
        : ''}
        ${logged
        ? html`
          <nav role="navigation" aria-label="Secondary navigation" class="flex flex-auto w-100 h-100 items-center justify-end pl2">
            <ul class="list ma0 pa0 flex h-100">
              ${this.state.cache(DropdownMenu, 'header-profile-dropdown').render({
                theme: isAdminRoute ? 'dark' : 'light',
                showIcon: true,
                showImage: true,
                scope: '/user',
                imageSrc: this.local.profile.data.avatar ? this.local.profile.data.avatar.medium : '',
                style: 'left:auto', // align right
                align: 'right',
                items: [
                  {
                    html: html`
                      <li class="flex items-start justify-end">
                        <a class="link dim flex items-center pa2 w-100" href=${process.env.OAUTH_HOST}>
                          <div class="fl w-100 mw3">
                            <div class="db aspect-ratio aspect-ratio--1x1 bg-dark-gray bg-dark-gray--dark">
                              <figure class="ma0">
                                <img src=${this.local.profile.data.avatar ? this.local.profile.data.avatar.medium : imagePlaceholder(60, 60)} width=60 height=60 class="aspect-ratio--object z-1" />
                                <figcaption class="clip">${this.local.profile.data.nickname}</figcaption>
                              </figure>
                            </div>
                          </div>
                          <div class="flex flex-column ml2">
                            <span class="f4 pb2">${this.local.profile.data.nickname}</span>
                            <span class="f5 lh-copy light-gray">Edit profile</span>
                          </div>
                        </a>
                      </li>
                    `
                  },
                  {
                    roles: ['label-owner'],
                    hidden: !profiles.length,
                    html: html`
                      <li>
                        <a href="/user/profiles" class="link db dim ph2 pt2 grey w-100">
                          Profiles
                        </a>
                        <ul class="flex flex-column list ma0 pa0">
                          ${profiles.map((item) => {
                            const { id, gravatar, nickname } = item

                            return html`
                              <li class="flex">
                                <a href="/user/profiles/${id}" class="flex items-center link pa2 dim">
                                  <div class="fl w-100 mw2">
                                    <div class="db aspect-ratio aspect-ratio--1x1 bg-dark-gray bg-dark-gray--dark">
                                      <figure class="ma0">
                                        <img src=${gravatar || imagePlaceholder(32, 32)} width=32 height=32 class="aspect-ratio--object z-1" />
                                        <figcaption class="clip">${nickname}</figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                  <div class="ml2">
                                    <span class="f5 lh-copy">${nickname}</span>
                                  </div>
                                </a>
                              </li>
                            `
                          }).slice(0, 3)}
                        </ul>
                      </li>
                    `
                  },
                  {
                    text: 'Admin',
                    roles: ['admin', 'resonate-coop', 'uploader'],
                    divider: true
                  },
                  {
                    roles: ['admin', 'resonate-coop', 'uploader'],
                    text: 'Dashboard',
                    href: '/user/admin'
                  },
                  {
                    text: 'App',
                    divider: true
                  },
                  {
                    text: 'FAQ',
                    href: '/faq'
                  },
                  {
                    text: 'Support',
                    target: '_blank',
                    href: 'https://resonate.is/support'
                  },
                  {
                    text: 'Log out',
                    href: `https://${process.env.APP_DOMAIN}/api/user/logout`
                  }
                ].filter(({ roles, hidden }) => {
                  if (hidden) return false
                  if (!roles) return true
                  if (staff.includes(this.local.profile.data.role)) return true
                  if (roles.includes(this.local.profile.data.role)) return true
                  return false
                })
              })}
            </ul>
          </nav>`
        : ''}
      </header>
    `
  }

  beforerender (el) {
    this.target = html`<div class="absolute top-0 left-0"></div>`

    document.body.appendChild(this.target)

    this.stopObserving = onIntersect(this.target, {
      root: null,
      rootMargin: '100px'
    }, this.onEnter, this.onExit)
  }

  onEnter () {
    if (this.local.sticky === true) {
      const timingProperties = {
        duration: 300,
        fill: 'forwards'
      }
      const keyFrames = [
        { transform: 'translateY(100%)' },
        { transform: 'translateY(0)' }
      ]
      const animate = animation(keyFrames, timingProperties)
      const move = animate(this.element)
      move.play()
      this.local.sticky = false
      this.rerender()
    }
  }

  onExit () {
    const timingProperties = {
      duration: 300,
      fill: 'forwards'
    }
    const keyFrames = [
      { transform: 'translateY(-100%)' },
      { transform: 'translateY(0)' }
    ]
    const animate = animation(keyFrames, timingProperties)
    const move = animate(this.element)
    move.play()
    this.local.sticky = true
    this.rerender()
  }

  unload () {
    document.body.removeChild(this.target)
    this.stopObserving()
  }

  update (props) {
    return props.href !== this.local.href ||
      props.profile.data.id !== this.local.profile.data.id
  }
}

module.exports = Header
