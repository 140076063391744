const Component = require('choo/component')
const html = require('choo/html')
const clone = require('shallow-clone')
const nanostate = require('nanostate')
const Loader = require('@resonate/play-count-component')
const compare = require('nanocomponent/compare')
const renderMessage = require('../../elements/message')
const ListItem = require('./item')

class Tracks extends Component {
  constructor (id, state, emit) {
    super(id)

    this.state = state
    this.emit = emit

    this.local = state.components[id] = Object.create({
      machine: nanostate.parallel({
        request: nanostate('idle', {
          idle: { start: 'loading' },
          loading: { resolve: 'data', reject: 'error', reset: 'idle' },
          data: { reset: 'idle', start: 'loading' },
          error: { reset: 'idle', start: 'loading' }
        }),
        loader: nanostate('off', {
          on: { toggle: 'off' },
          off: { toggle: 'on' }
        })
      })
    })

    this.local.error = {}

    this.local.machine.on('request:error', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.transitions.request.event('error', nanostate('error', {
      error: { start: 'loading' }
    }))

    this.local.machine.on('request:noResults', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.transitions.request.event('noResults', nanostate('noResults', {
      noResults: { start: 'loading' }
    }))

    this.local.machine.on('loader:toggle', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.on('request:reject', () => {
      if (this.element) this.rerender()
    })
  }

  createElement (props) {
    const state = this.state
    const emit = this.emit

    this.local.title = props.title
    this.local.items = clone(props.items)
    this.local.filters = props.filters

    const machine = {
      idle: () => {},
      loading: {
        on: () => {
          const loader = new Loader('loader', state, emit).render({
            count: 3,
            options: { animate: true, repeat: true, reach: 9, fps: 10 }
          })

          return html`
            <div class="flex flex-column flex-auto items-center justify-center h5">
              ${loader}
            </div>
          `
        },
        off: () => {}
      }[this.local.machine.state.loader],
      noResults: () => renderMessage({ message: 'No results to display' }),
      error: () => renderMessage({ type: 'error', message: this.local.error.message }),
      data: () => renderItems(this.local.items)
    }[this.local.machine.state.request]

    return html`
      <div class="flex flex-column flex-auto w-100">
        ${machine()}
      </div>
    `

    function renderItems (items) {
      return html`
        <ul class="list ma0 pa0 flex flex-column w-100 ph3">
          ${items.map((item, index) => {
            return state.cache(ListItem, `track-item-${index}`).render({
              data: item
            })
          })}
        </ul>
      `
    }
  }

  update (props) {
    return compare(this.local.items, props.items)
  }
}

module.exports = Tracks
