const html = require('choo/html')

function main (state, emit) {
  return html`
    <div class="flex flex-column flex-auto">
    </div>
  `
}

module.exports = main
