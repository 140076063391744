const html = require('choo/html')
const Header = require('../components/header')
const Footer = require('../components/footer')
const link = require('@resonate/link-element')

function Layout (view) {
  return (state, emit) => {
    return html`
      <div id="app" class="pb6">
        ${noscript()}
        ${state.cache(Header, 'header').render({
          href: state.href,
          profile: state.profile
        })}
        <div class="sticky z-1" style="top:3rem">
          ${login(state)}
        </div>
        <main class="flex pb6">
          ${view(state, emit)}
        </main>
        ${state.cache(Footer, 'footer').render()}
      </div>
    `
  }
}

function noscript () {
  const javascript = link({
    href: 'https://www.enable-javascript.com',
    prefix: 'b',
    text: 'Javascript enabled',
    target: '_blank'
  })

  const faq = link({
    href: '/faq',
    prefix: 'b',
    text: 'See frequently asked questions'
  })

  const support = link({
    href: 'https://resonate.is/support',
    prefix: 'b',
    text: 'Get support',
    target: '_blank'
  })

  return html`
    <noscript>
      <p class="f5 lh-copy pl3">
        For the best experience with Resonate, please ensure that your browser has ${javascript}.
      </p>
      <p class="f5 lh-copy pl3">
        Looking for something else ? ${faq}
      </p>
      <p class="f5 lh-copy pl3">
        Our team is here to help you. ${support}
      </p>
    </noscript>
  `
}

function login (state) {
  const unlogged = state.profile.resolved && !state.profile.data.id

  if (!unlogged) return

  return html`
    <div class="flex flex-column flex-row-l flex-auto w-100 bg-black shadow-contour white ph3">
      <div class="flex flex-column mr4-l">
        <h1 class="flex flex-column lh-title fw4 f3">
          Resonate Upload Tool
          <small class="lh-copy f4">For artists, labels and listeners.</small>
        </h1>
      </div>
      <div class="flex flex-auto items-center mr2 mb4 mb0-l justify-end-l">
        <div>
          ${link({
            prefix: 'link flex items-center bg-white ba bw b--white black pv2 ph3 grow dim mr4',
            href: `${process.env.APP_HOST}/api/user/connect/resonate`,
            text: 'Login'
            })}
        </div>
        <div>
          ${link({
            prefix: 'link flex items-center db bg-transparent ba bw b--white white pv2 ph3 grow dim',
            target: '_blank',
            href: `${process.env.OAUTH_HOST}/join`,
            text: 'Join'
          })}
        </div>
      </div>
    </div>
  `
}

module.exports = Layout
