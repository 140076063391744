/* global fetch */

const setTitle = require('../lib/title')
const generateApi = require('../lib/api')
const storage = require('localforage')
const isUrl = require('validator/lib/isURL')

const isStaff = (role) => {
  const staff = ['admin', 'resonate-coop', 'uploader']
  return staff.includes(role)
}

storage.config({
  name: 'resonate',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'app', // Should be alphanumeric, with underscores.
  description: 'Resonate storage'
})

function app () {
  return (state, emitter) => {
    state.api = generateApi()

    state.profile = state.profile || {
      resolved: false,
      data: {}
    }

    emitter.on('error', (err) => {
      if (Array.isArray(err)) {
        for (const error of err) {
          if (error.schemaPath) {
            const dataPath = error.dataPath
            // const keyword = error.keyword
            // const param = error.params[keyword]
            // handle validation error
            emitter.emit('notify', {
              type: 'error',
              timeout: 5000,
              message: `Validation error: Param \`${dataPath}\` ${error.message}`
            })
          }
          console.error(error.message)
        }
      }
    })

    emitter.on('lazy:success', () => {
      console.log('successfully loaded route')
    })

    emitter.on('redirect', (props = {}) => {
      const {
        dest = '/',
        timeout = 3000,
        update = false,
        message = 'Redirecting...'
      } = props

      if (message) {
        emitter.emit('notify', { timeout, message })
      }

      if (isUrl(dest)) {
        return setTimeout(() => {
          window.location = dest
        }, timeout)
      }

      emitter.emit(state.events.REPLACESTATE, dest)

      if (update) {
        emitter.emit('update')
      }
    })

    emitter.on(state.events.DOMCONTENTLOADED, () => {
      const isAdminRoute = state.route.split('/')[1] === 'admin'

      state.api = generateApi({
        prefix: isAdminRoute ? '/api/user/admin' : '/api/user'
      })

      setMeta(state, emitter)
      emitter.emit('load:profile')
      emitter.emit(`route:${state.route}`)
    })

    emitter.on('load:profile', async () => {
      const authRequired = state.route.split('/')[0] === 'user'
      const isAdminRoute = state.route.split('/')[1] === 'admin'

      try {
        const response = await (await fetch('/api/user/profile')).json()

        if (response.status === 401 || response.status === 404) {
          if (authRequired) {
            return emitter.emit('redirect', { message: 'You are not authenticated' })
          }
          return
        }

        state.profile.data = response.data
        state.token = response.data.token

        emitter.emit(state.events.RENDER)

        if (isAdminRoute && !isStaff(state.profile.data.role)) {
          return emitter.emit('redirect', {
            message: 'You are not authorized to access this pageÃ¢ÂÂ¦'
          })
        }
      } catch (err) {
        emitter.emit('error', err)
      } finally {
        state.profile.resolved = true

        emitter.emit(state.events.RENDER)
      }
    })

    emitter.on('set:usergroup', (usergroup) => {
      state.usergroup = usergroup
      emitter.emit('render')
    })

    emitter.on(state.events.NAVIGATE, () => {
      const isAdminRoute = state.route.split('/')[1] === 'admin'

      state.api = generateApi({
        prefix: isAdminRoute ? '/api/user/admin' : '/api/user'
      })

      emitter.emit('load:profile')

      window.scrollTo(0, 0)
      setMeta(state, emitter)
      emitter.emit(`route:${state.route}`)
    })
  }
}

function setMeta (state, emitter) {
  const title = {
    'user/earnings': 'Earnings',
    'user/earnings/analytics': 'Earnings + Analytics',
    'user/plays': 'Plays',
    'user/plays/analytics': 'Plays + Analytics',
    'user/releases': 'Releases',
    'user/releases/new': 'Create new release',
    'user/playlists': 'Playlists',
    'user/playlists/new': 'Create new playlist',
    'user/files': 'Files',
    'user/files/:id': 'View file',
    'user/profile/edit': 'Edit profile',
    'user/profile': 'Profile',
    'user/tracks': 'Tracks',
    'user/admin': 'Dashboard',
    'user/admin/users/:id/earnings': 'Earnings',
    'user/admin/users/:id/earnings/analytics': 'Earnings + Analytics',
    'user/admin/users/:id/plays': 'Plays',
    'user/admin/users/:id/plays/analytics': 'Plays + Analytics',
    'user/admin/releases': 'Releases',
    'user/admin/releases/new': 'Create new release',
    'user/admin/playlists': 'Playlists',
    'user/admin/playlists/new': 'Create new playlist',
    'user/admin/files': 'Files',
    'user/admin/files/:id': 'View file',
    'user/admin/profile/edit': 'Edit profile',
    'user/admin/profile': 'Profile',
    'user/admin/tracks': 'Tracks'
  }[state.route] || '...'

  state.shortTitle = title

  const fullTitle = state.route === '/' ? setTitle() : setTitle(title)

  emitter.emit('meta', {
    title: fullTitle,
    'twitter:card': 'summary_large_image',
    'twitter:title': fullTitle,
    'twitter:site': '@resonatecoop'
  })
}

module.exports = app
