const Component = require('choo/component')
const html = require('choo/html')
const clone = require('shallow-clone')
const nanostate = require('nanostate')
const Loader = require('@resonate/play-count-component')
const compare = require('nanocomponent/compare')
const renderMessage = require('../../elements/message')

class Files extends Component {
  constructor (id, state, emit) {
    super(id)

    this.state = state
    this.emit = emit

    this.local = state.components[id] = Object.create({
      machine: nanostate.parallel({
        request: nanostate('idle', {
          idle: { start: 'loading' },
          loading: { resolve: 'data', reject: 'error', reset: 'idle' },
          data: { reset: 'idle', start: 'loading' },
          error: { reset: 'idle', start: 'loading' }
        }),
        loader: nanostate('off', {
          on: { toggle: 'off' },
          off: { toggle: 'on' }
        })
      })
    })

    this.local.machine.on('request:noResults', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.transitions.request.event('noResults', nanostate('noResults', {
      noResults: { start: 'loading' }
    }))

    this.local.machine.on('loader:toggle', () => {
      if (this.element) this.rerender()
    })

    this.local.machine.on('request:reject', () => {
      if (this.element) this.rerender()
    })
  }

  createElement (props) {
    const state = this.state
    const emit = this.emit

    this.local.tables = clone(props.tables)

    const machine = {
      loading: {
        on: () => {
          const loader = new Loader('loader', state, emit).render({
            count: 3,
            options: { animate: true, repeat: true, reach: 9, fps: 10 }
          })

          return html`
            <div class="flex flex-column flex-auto items-center justify-center h5">
              ${loader}
            </div>
          `
        },
        off: () => {}
      }[this.local.machine.state.loader],
      noResults: () => renderMessage({ message: 'No results can be fetched' }),
      error: () => renderMessage({ type: 'error', message: 'Failed to fetch' }),
      data: () => {
        return renderTable(this.local)
      }
    }[this.local.machine.state.request]

    return html`
      <div class="flex flex-column flex-auto">
        <div class="flex">
          <h2 class="f3 fw4 pa3">
            Files
          </h2>
        </div>
        <div class="flex overflow-auto">
          ${machine()}
        </div>
      </div>
    `

    function renderTable (props) {
      const { tables } = props

      return tables.map(({ tableRows, headRows }) => {
        return html`
          <table class="f6 w-100 ph3 pr2 center" cellspacing="0">
            <thead>
              <tr class="stripe-dark">
                ${headRows.map((row) => {
                  return html`
                    <th class="fw1 f5 tl pl1 pl3-l pv3 bg-white">${row.name}</th>
                  `
                })}
              </tr>
            </thead>
            <tbody class="lh-copy">
              ${tableRows.map(item => {
                return html`
                  <tr class="stripe-dark">
                    ${Object.entries(item).map(([k, v]) => {
                      if (v !== null && typeof v === 'object') {
                        return html`<td class="pl1 pl3-l pv3">${v.html}</td>`
                      }
                      return html`<td class="truncate pl1 pl3-l pv3">${v}</td>`
                    })}
                  </tr>
                `
              })}
            </tbody>
          </table>
        `
      })
    }
  }

  update (props) {
    return compare(this.local.tables, props.tables)
  }
}

module.exports = Files
